import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CustomBox from '../components/CustomBox';
import { Box, capitalize, Grid, ImageList, ImageListItem, Typography } from '@mui/material';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from 'victory';

type PokemonName = string;

interface Stat {
  base_stat: number;
  effort: number;
  stat: {
    name: string;
    url: string;
  };
}

interface Type {
  slot: number;
  type: {
    name: string;
    url: string;
  };
}

interface PokemonDetails {
  abilities?: Array<{ ability: { name: string } }>;
  base_experience?: number;
  forms?: Array<{ name: string }>;
  game_indices?: Array<{ game_index: number; version: { name: string } }>;
  height?: number;
  held_items?: Array<{ item: { name: string } }>;
  id?: number;
  is_default?: boolean;
  location_area_encounters?: string;
  moves?: Array<{ move: { name: string } }>;
  name?: string;
  order?: number;
  past_types?: Array<{ generation: { name: string }; types: Type[] }>;
  species?: {
    name?: string;
    url?: string;
  };
  sprites?: {
    back_default: string | null;
    back_female: string | null;
    back_shiny: string | null;
    back_shiny_female: string | null;
    front_default: string | null;
    front_female: string | null;
    front_shiny: string | null;
    front_shiny_female: string | null;
  };
  stats?: Stat[];
  types?: Type[];
  weight?: number;
}

const decimetersToFeetInches = (dm: number): string => {
  const inches = dm * 3.937;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);
  return `${feet}′ ${remainingInches}″`;
};

const hectogramsToPounds = (hg: number): string => {
  const pounds = (hg * 0.220462).toFixed(1);
  const kilograms = (hg / 10).toFixed(1);
  return `${pounds} lbs (${kilograms} kg)`;
};

export default function PokemonPage(): React.ReactElement {
  const { name: PokemonName } = useParams<PokemonName>();
  const [loading, setLoading] = useState(true);
  const [pokemonDetails, setPokemonDetails] = useState<PokemonDetails | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://pokeapi.co/api/v2/pokemon/${PokemonName?.toLowerCase()}`, {
          cancelToken: source.token,
        });
        setLoading(false);
        setPokemonDetails(response.data);
      } catch (error) {
        setLoading(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          setError('An error occurred while fetching the Pokemon data.');
          console.error('API request failed:', error);
        }
      }
    };

    fetchData();

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [PokemonName]);

  if (loading) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    );
  }

  if (!pokemonDetails) {
    return (
      <div>
        <p>No Pokemon data available.</p>
      </div>
    );
  }

  const statsData = pokemonDetails.stats?.map(stat => ({
    stat: capitalize(stat.stat.name),
    value: stat.base_stat,
  }));

  const spriteKeys = [
    'front_default',
    'front_shiny',
    'back_default',
    'back_shiny',
    'front_female',
    'back_female',
    'front_shiny_female',
    'back_shiny_female',
  ];

  return (
    <CustomBox headerText={capitalize(PokemonName ?? '')}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Basic Information
            </Typography>
            <Typography variant="body1">
              <strong>Name:</strong> {capitalize(pokemonDetails.name ?? '')}
            </Typography>
            <Typography variant="body1">
              <strong>Height:</strong> {decimetersToFeetInches(pokemonDetails.height ?? 0)}
            </Typography>
            <Typography variant="body1">
              <strong>Weight:</strong> {hectogramsToPounds(pokemonDetails.weight ?? 0)}
            </Typography>
            <Typography variant="body1">
              <strong>Base Experience:</strong> {pokemonDetails.base_experience}
            </Typography>
            <Typography variant="body1">
              <strong>Types:</strong> {pokemonDetails.types?.map(type => capitalize(type.type.name)).join(', ')}
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Abilities
            </Typography>
            <ul>
              {pokemonDetails.abilities?.map((ability, index) => (
                <li key={index}>{capitalize(ability.ability.name)}</li>
              ))}
            </ul>
          </Box>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Base Stats
            </Typography>
            <VictoryChart domainPadding={20}>
              <VictoryBar
                data={statsData}
                x="stat"
                y="value"
                style={{ data: { fill: '#4caf50' } }}
                labels={({ datum }) => `${datum.stat}: ${datum.value}`}
                labelComponent={<VictoryTooltip />}
              />
              <VictoryAxis
                style={{
                  tickLabels: { angle: -45, fontSize: 10, padding: 10 },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
              />
            </VictoryChart>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageList cols={2}>
            {spriteKeys.map((key, index) => {
              const spriteUrl = pokemonDetails.sprites?.[key as keyof typeof pokemonDetails.sprites];
              return (
                spriteUrl && (
                  <ImageListItem key={index}>
                    <img
                      src={spriteUrl ?? ''}
                      alt={`${pokemonDetails.name} sprite ${index}`}
                    />
                  </ImageListItem>
                )
              );
            })}
          </ImageList>
        </Grid>
      </Grid>
    </CustomBox>
  );
}