import { createTheme, type Theme } from '@mui/material/styles'

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: '#00FF00'
    }
  },
  typography: {
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.5rem'
    }
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem'
        }
      }
    }
  }
})
