import { styled, Box, Button, TextField, Grid } from '@mui/material';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import PokeLogo from '../images/Logo.png';

const BannerContainer = styled(Box)`
  border: 1px solid black;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 24px;
  background: #EAE8E9;
  justify-content: space-between;
`;

const HeaderStyled = styled(Typography)`
  display: none;
  padding-right: 16px;
  text-align: center;
`;

export default function Banner(): React.ReactElement {
  const navigate = useNavigate();
  const [pokemonNameSearch, setPokemonNameSearch] = useState('');

  const SubmitIt = () => {
    navigate('pokemon/' + pokemonNameSearch);
  };

  return (
    <BannerContainer>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <Link to="/">
            <img src={PokeLogo} style={{ width: '100%' }} />
          </Link>
          <HeaderStyled variant="h1">PokéSupport</HeaderStyled>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="flex-end">
          <TextField
            label="Pokemon Search"
            placeholder="type here..."
            value={pokemonNameSearch}
            onChange={(e) => setPokemonNameSearch(e.target.value)}
            size="small"
            sx={{ mr: 2, width: '100%', background: 'white' }}
          />
          <Button onClick={SubmitIt} variant="contained" color="primary">
            Search
          </Button>
        </Grid>
      </Grid>
    </BannerContainer>
  );
}