import React, { useState, useEffect } from 'react'
import PokemonList from './PokemonList'
import axios from 'axios'
import Pagination from './Pagination'
import CustomBox from '../components/CustomBox'

export interface PokemonNameAndURL{
  map(arg0: (p: any) => JSX.Element): React.ReactNode
  readonly name?: string,
  readonly url?: string,
// any props that come into the component
}


export interface PokemonNameAndURLArray extends Array<PokemonNameAndURL>{}


function PokemonApiList (): React.ReactElement {
  const [pokemon, setPokemon] = useState<PokemonNameAndURLArray>([])
  const [currentPageUrl, setCurrentPageUrl] = useState('https://pokeapi.co/api/v2/pokemon')
  const [nextPageUrl, setNextPageUrl] = useState('')
  const [prevPageUrl, setPrevPageUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    let cancel: () => void
    // we want this to rerenderr teh applocation when it happens
    axios.get(currentPageUrl, {
      // eslint-disable-next-line no-return-assign
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(res => {
      setLoading(false)
      setNextPageUrl(res.data.next)
      setPrevPageUrl(res.data.previous)
      setPokemon(res.data.results)
      console.log('res.data.results =', res.data.results)
    }).catch(function (error) {
      console.log(error.config ?? 'an error occured')
    })

    return () => {
      // clean up after last call, resolves race condition, multiple calls similtaniously
      cancel()
    }
  }, [currentPageUrl])

  function goToNextPage (): void {
    setCurrentPageUrl(nextPageUrl)
  }
  function goToPrevPage (): void {
    setCurrentPageUrl(prevPageUrl)
  }

  if (loading) {
    return (
      <CustomBox headerText="Pokemon List">
        <p>Loading...</p>
        </CustomBox>
    )
  }
  return (
    <CustomBox headerText="Pokemon List">
        <Pagination
          goToNextPage={(nextPageUrl !== null) ? goToNextPage : null}
          goToPrevPage={(prevPageUrl !== null) ? goToPrevPage : null}
        />
        <PokemonList pokemon={pokemon}/>
      </CustomBox>
  )
}

export default PokemonApiList
