import React from 'react'
import { Container, Grid } from '@mui/material'
import PokemonApiList from '../pokeList/PokemonApiList'
import PokemonApiNews from '../news/PokemonApiNews'
import PokeSearch from '../PokeSearch'

export default function Homepage (): React.ReactElement {
  return (
    <div className="Homepage">

    <Grid container spacing={2} >
      {/* <Grid xs={12} item>
        <PokeSearch/>
      </Grid> */}
      {/* <Grid xs={12} md={8} item>
      <PokemonApiNews/>
      </Grid> */}
      <Grid xs={12} item>
        <PokemonApiList/>
      </Grid>
    </Grid>
  </div>
  )
}
