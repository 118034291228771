import React, { type ReactElement } from 'react'
import Banner from './components/Banner'
import Homepage from './pages/Homepage'
import PokemonPage from './pages/PokemonPage'
import PageNotFound from './pages/PageNotFound'
// import PokemonApiNews from './news/PokemonApiNews'
// import PokeSearch from './PokeSearch'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Container from '@mui/material/Container'

const AdminLayout = (): React.ReactElement => (
  <div>

    <Container maxWidth={'lg'}>
    <Banner/>
        <Outlet />
    </Container>
  </div>
)

function App (): ReactElement {
  return (
    <>

    <BrowserRouter>
    <Routes>
    <Route path="/" element={<AdminLayout />}>
      <Route index element={<Homepage />}/>
      <Route path="/pokemon/:name" element={<PokemonPage />} />
      <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>

    {/* <Switch>
      <Route exact={true} path="/" component={Homepage}/>
      <Route exact={true} path="/pokemon/:id(\d+)" component={PokemonPage}/>
      <Route component={PageNotFound}/>
    </Switch> */}
    </>

  // <div className="App">
  //   <Container maxWidth={'xl'}>
  //   <Grid container spacing={2} >
  //     <Grid xs={12} item>

  //     </Grid>
  //     <Grid xs={12} item>
  //       <PokeSearch/>
  //     </Grid>
  //     <Grid xs={12} md={8} item>
  //     <PokemonApiNews/>
  //     </Grid>
  //     <Grid xs={12} md={4} item>
  //       <PokemonApiList/>
  //     </Grid>
  //   </Grid>
  //   </Container>
  // </div>
  )
}

export default App
