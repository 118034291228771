import { capitalize, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';

interface PokemonNameAndURL {
  readonly name?: string;
  readonly url?: string;
}
interface PokemonNameAndURLArray extends Array<PokemonNameAndURL> {}

interface PokemonListProps {
  pokemon: PokemonNameAndURLArray;
}

const GridItem = styled(Grid)`
  border: 1px solid black;
  padding: 8px;
  :hover {
    background: #eae8e9;
  }
  a {
    text-decoration: none;
    color: black;
  }
`;

export default function PokemonList({ pokemon }: PokemonListProps): React.ReactElement {
  const getPokemonId = (url: string): string => {
    const parts = url.split('/');
    return parts[parts.length - 2];
  };

  return (
    <Grid container spacing={2}>
      {pokemon.map((p: PokemonNameAndURL) => (
        <GridItem key={p.name} item xs={6} sm={3} md={3}>
          <Link to={'/pokemon/' + p.name}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                {p.url && (
                  <img
                    src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${getPokemonId(p.url)}.png`}
                    alt={p.name}
                    width="100%"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <Typography variant="body1" sx={{ padding: '36px 0' }}>
                  {capitalize(p.name ?? '')}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </GridItem>
      ))}
    </Grid>
  );
}