import React from 'react'
import Button from '@mui/material/Button'

interface Props {
  goToNextPage: (() => void) | null
  goToPrevPage: (() => void) | null
  // any props that come into the component
}

export default function Pagination ({ goToNextPage, goToPrevPage }: Props): React.ReactElement {
  return (
  <div style={{textAlign: "center", margin: "24px 0"}}>
    {(goToPrevPage != null) && <Button color='primary' variant='contained' onClick={goToPrevPage} disabled={goToPrevPage === null}>Previous</Button>}
    {(goToNextPage != null) && <Button onClick={goToNextPage}>Next</Button>}
  </div>
  )
}
