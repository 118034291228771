import React from 'react'
import { styled, Box, Typography } from '@mui/material'

const BoxStyled = styled(Box)`
    background: white;
`

const HeaderStyled = styled(Typography)`
    text-align: center;
`

interface Props {
  headerText: string
  children?: React.ReactNode
}

export default function CustomBox ({ children, headerText }: Props): React.ReactElement {
  return (
    <BoxStyled className="CustomBox" p={2} sx={{ border: '1px solid black' }}>
        <HeaderStyled variant='h2'>{headerText}</HeaderStyled>
   <>{ children }</>
    </BoxStyled>
  )
}
